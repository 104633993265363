// InfoMenu.tsx
import React, { useState, useEffect, useContext } from "react";
import './InfoMenu.css';
import CuteLoading from "../CuteLoading/CuteLoading";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Navigate, useNavigate } from "react-router-dom";
import { MyContext } from "../../MyContext";


interface JwtPayload {
  id: string;
  // Add other properties that you expect in your JWT payload
}

const InfoMenu: React.FC = () => {
  const context = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [electronic_wallet, setelectronic_wallet] = useState<number>(0);
  const [flexible_wallet, setflexible_wallet] = useState<number>(0);
  const [gold_coin, setgold_coin] = useState<number>(0);
  const [total_income, settotal_income] = useState<number>(0);
  const [total_commission_fee, settotal_commission_fee] = useState<number>(0);
  const [total_team_size, settotal_team_size] = useState<number>(0);
  const [cumulative_withdrawal, setcumulative_withdrawal] = useState<number>(0);
  const [cumulative_recharge, setcumulative_recharge] = useState<number>(0);
  const [useremail, setuseremail] = useState<string>("");
  const [userphoneNumber, setuserphoneNumber] = useState<string>("");
  const [totalEarning, setTotalEarning] = useState<string>("");
  const [flexibleBalance, setFlexibleBalance] = useState<number>(0);
  const [totalfee, settotalfee] = useState<number>(0);
  const { mybalance, setMybalance } = context;
  const navigate = useNavigate();


  useEffect(() => {
    setLoading(true)
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: JwtPayload = jwtDecode(token);
      const userId = decoded.id;
      axios
        .post(`${process.env.REACT_APP_BACKEND_PORT}/api/get_user_id/${userId}`)
        .then((response) => {
          setLoading(false);
          // setelectronic_wallet(response.data.)
          // setflexible_wallet(response.data.)
          setgold_coin(response.data.gold_coin)
          // settotal_income(response.data.)
          // settotal_commission_fee(response.data.)
          settotal_team_size(response.data.invites)
          setcumulative_withdrawal(response.data.all_withdraw)
          setcumulative_recharge(response.data.first_investment)
          setuseremail(response.data.email)
          setuserphoneNumber(response.data.phoneNumber)
          setMybalance(response.data.electron_balance);
          setTotalEarning(response.data.total_earning)
          setFlexibleBalance(response.data.balance);
          settotalfee(response.data.total_fee)
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching messages", error);
        });
    } else {
      console.log(`No token found.`);
    }
  }, [])

  return (
    <div className="info-dashboard-container">
      <div className="info-wallet-header">
        {useremail && <span style={{ color: "#fff", textAlign: "left" }}>{useremail}</span>}
        {userphoneNumber && <span style={{ color: "#fff", textAlign: "left" }}>{userphoneNumber}</span>}
      </div>
      <div className="info-wallet-section">
        <div className="info-wallet">
          <div className="info-wallet-amount">{mybalance}</div>
          <div className="info-wallet-title">Electronic wallet (USDT)</div>
        </div>
        <div className="info-wallet" onClick={() => navigate("/wallet")}>
          <div className="info-wallet-amount">{flexibleBalance}</div>
          <div className="info-wallet-title">Flexible wallet (USDT)</div>
        </div>
        <div className="info-wallet">
          <div className="info-wallet-amount">{gold_coin}</div>
          <div className="info-wallet-title">Gold coin</div>
        </div>
      </div>

      <div className="info-income-section">
        <div className="info-income-row1">
          <div className="info-income-box">
            <div className="info-income-amount">{totalEarning}</div>
            <div className="info-income-title">Total Income (USDT)</div>
          </div>
          <div className="info-income-box">
            <div className="info-income-amount">{totalfee}</div>
            <div className="info-income-title">Total commission fee</div>
          </div>
        </div>
        <div className="info-income-row2">
          <div className="info-income-box">
            <div className="info-income-amount">{cumulative_recharge}</div>
            <div className="info-income-title">Cumulative recharge (USDT)</div>
          </div>
          <div className="info-income-box">
            <div className="info-income-amount">{cumulative_withdrawal}</div>
            <div className="info-income-title">Cumulative withdrawal (USDT)</div>
          </div>
          <div className="info-income-box">
            <div className="info-income-amount">{total_team_size}</div>
            <div className="info-income-title">Total team size</div>
          </div>
        </div>
      </div>
      {loading && <CuteLoading />}
    </div >
  );
};

export default InfoMenu;
